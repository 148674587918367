module.exports = [{
      plugin: require('/home/geraint/Projects/GeraintAnderson.com/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Fira Sans","Playfair Display"]}},
    },{
      plugin: require('/home/geraint/Projects/GeraintAnderson.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/geraint/Projects/GeraintAnderson.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
